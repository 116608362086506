<template>
    <div class="relative" :style="{width}">
        <div :class="{'grid items-center gap-6 grid-cols-12': displayInline}">
            <div v-show="label.length > 0" class="flex items-center" :class="classLabel">
                <div class="text-overline font-normal" :class="[{'error': !!errorMessage, 'readonly': readonly}, labelClass]"
                     :style="{'color': labelColor}">
                    {{ label }}
                </div>

                <c-tooltip v-if="tooltip" background-color="#EBE7E6" color="black" :position="tooltip.position">
                    <template v-slot:activator="{on}">
                        <div v-on="on">
                            <icons-question-mark-circle-solid class="text-grey5 w-4 h-4 ml-1"></icons-question-mark-circle-solid>
                        </div>
                    </template>

                    <div class="relative">
                        <div class="pt-1 pb-2 pl-3 pr-[.625rem] rounded">
                            <div class="flex items-center text-lg text-[.875rem] font-medium">
                                <icons-light-bulb-outline class="w-[1.125rem] h-[1.125rem] mr-2"></icons-light-bulb-outline>
                                {{ tooltip.title }}
                            </div>

                            <div class="text-caption leading-[1.125rem]" v-html="tooltip.text"></div>
                        </div>
                    </div>
                </c-tooltip>
            </div>

            <!--  permet d'avoir un vrai alignement vertical de l'élement absolut, thx Ali. H -->
            <div class="flex flex-col"
                 :class="classItem">
                <div class="flex items-center gap-6">
                    <div class="flex items-center relative"
                         :class="classInput"
                         :style="'width: ' + widthInput"
                    >
                        <input
                            class="w-full px-3 py-4 rounded border focus:outline-none"
                            :class="[
                                {'border-red-error': errors.length > 0, 'readonly': readonly, 'bg-transparent': grey},
                                from === 'maslow' ? 'focus:border-grey5 border-grey3' : 'border-[#1F2945]'
                            ]"
                            :autocomplete="autocomplete"
                            :placeholder="placeholder"
                            v-model="content"
                            @blur="handleBlur"
                            :type="type === 'password' ? (showPassword ? 'text' : type) : (type === 'number' ? 'text' : type)"
                            :inputmode="type === 'number' ? 'numeric' : 'text'"
                            :style="'height: ' + height"
                            :min="min"
                            :readonly="readonly"
                            :rules="rules"
                            :name="vid"
                        >

                        <div v-if="type === 'password'"
                             class="w-6 absolute text-grey6 float-right right-4 cursor-pointer"
                             @click="toggleShowPassword">
                            <icons-eye-off-outline v-if="showPassword"></icons-eye-off-outline>
                            <icons-eye-outline v-else></icons-eye-outline>
                        </div>

                        <div v-if="insideRightText" :class="colorInsideRight" class="mr-2 absolute float-right right-0">
                            {{ insideRightText }}
                        </div>
                    </div>

                    <slot></slot>
                </div>

                <div class="text-overline mt-1 text-red-error form-error-display" v-if="showValidationErrors && errorMessage"
                     :class="{'col-span-12': displayInline}">
                    <slot name="error">
                        {{ errorMessage }}
                    </slot>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>
import { toRef } from 'vue';
import { useField } from 'vee-validate';

const props = defineProps({
    vid: {
        type: String,
    },
    autocomplete: {
      type: [Boolean, String],
      default: false
    },
    type: {
        type: String,
        default: "text"
    },
    placeholder: {
        type: String,
        default: ""
    },
    label: {
        type: String,
        default: ""
    },
    width: {
        type: String,
        default: "100%"
    },
    widthInput: {
        type: String,
        default: "100%"
    },
    modelValue: {},
    showValidationErrors: {
        type: Boolean,
        default: false
    },
    rules: {
        type: [String,Object],
        default: ""
    },
    immediate: {
        type: Boolean,
        default: false
    },
    insideRightIcon: {
        type: String
    },
    insideRightText: {
        type: String
    },
    colorInsideRight: {
        type: String
    },
    labelColor: {
        type: String,
        default: "#2C2C2C"
    },
    labelClass: {
        type: String,
        default: ""
    },
    height: {
        type: String,
        default: "3rem"
    },
    readonly: {
        type: Boolean,
        default: false
    },
    grey: {
        type: Boolean,
        default: false
    },
    displayInline: {
        type: Boolean,
        default: false
    },
    colsLabelInline: {
        type: String,
        default: 'col-span-8'
    },
    colsItemInline: {
        type: String,
        default: 'col-span-4'
    },
    classGrey: {
        type: String,
        default: 'bg-grey1'
    },
    tooltip: {
        type: Object,
        default: null
    },
    nullOnDestroy: {
        type: Boolean,
        default: false
    },
    typeFormat: {
        type: Boolean,
        default: true,
    },
    min: {
        type: String,
        default: null
    },
    from: {
        type: String,
        default: 'maslow'
    }
});

const vid = toRef(props, 'vid');

const {
    value: inputValue,
    errorMessage,
    handleBlur,
    handleChange,
    meta,
    errors
} = useField(vid, props.rules, {
    initialValue: props.modelValue,
    syncVModel: true
});

</script>

<script>

export default {
    name: "TextField",
    emits:['update:modelValue'],
    watch: {
        modelValue() {
            this.content = this.modelValue;
        },
        content() {
            this.format();
        },
    },
    data() {
        return {
            content: null,
            showPassword: false,
        }
    },
    mounted() {
        this.content = this.modelValue;
    },
    beforeDestroy() {
        if (this.nullOnDestroy === true) {
            this.$emit('update:modelValue', null);
        }
    },
    methods: {
        format() {
            let value = this.content;

            if (value != null && this.type === "number") {
                value = value.toString().replace(/\s/g, '').replace(/[^0-9]/g, '');

                if (this.typeFormat) {
                    this.content = value !== '' ? new Intl.NumberFormat('fr-FR').format(value) : ''; // Permet d'avoir réellement un champ vide et non un 0
                }
            } else if (value != null && this.type === "siret") {
                value = value.toString().replace(/\s/g, '').replace(/[^0-9]/g, '');

                /*if (this.typeFormat) {
                    if (value.length > 0) {
                        let formattedString = [
                            value.substring(0,3),
                            value.substring(3,6),
                            value.substring(6,9),
                            value.substring(9,13)
                        ].join(" ").trim()

                        this.content = value !== '' ? formattedString: '';
                    }
                }*/
            }

            if (!this.typeFormat) {
                this.content = value;
            }

            this.$nextTick(() => this.handleInput());
        },
        handleInput() {
            if (this.type === 'number') {
                this.$emit("update:modelValue", (this.content != null && this.content !== '') ? this.content.replace(/\s/g, '') : this.content);
            } else {
                this.$emit("update:modelValue", this.content);
            }
        },
        toggleShowPassword() {
            this.showPassword = !this.showPassword;
        }
    },
    computed: {
        classLabel() {
            let obj = {'mb-3': !this.displayInline};
            obj[this.colsLabelInline] = this.displayInline;

            return obj;
        },
        classItem() {
            let obj = {};
            obj[this.colsItemInline] = this.displayInline;

            return obj;
        },
        classInput() {
            let obj = {'rounded': true};
            obj[this.classGrey] = this.grey;

            return obj;
        },
    },
}
</script>

<style scoped lang="scss">
::placeholder {
    color: #C2BBBA;
    opacity: 1;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    /* display: none; <- Crashes Chrome on hover */
    -webkit-appearance: none;
    margin: 0; /* <-- Apparently some margin are still there even though it's hidden */
}

input[type=number] {
    -moz-appearance: textfield; /* Firefox */
}
</style>
